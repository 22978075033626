import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Blog from "./Blog";
import BlogView from "./BlogView";
import Apps from "./Apps";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NotFound from "./NotFound";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Navigate replace to="/apps" />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/tag/:selectedTag" element={<Blog />} />
          <Route path="blog/:blogEntry" element={<BlogView />} />
           
          <Route path="apps" element={<Apps />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
