import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { amber } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, Stack, LinearProgress, Alert, Typography } from "@mui/material";
import Masonry from "@mui/lab/Masonry";

import BlogTagList from "./BlogTagList";
import BlogEntry from "./BlogEntry";
import { allEntriesFetcher, allTagsFetcher, getTagFetcher } from "./BlogQuery";
import XqyPage from "./XqyPage";
import { HeaderAndContents } from "./XqyLayouts";

const BlogTagsSection = (props) => {
  const [data, setData] = React.useState(false);
  const [error, setError] = React.useState(false);
  let params = useParams();
  let navigate = useNavigate();
  var currentTag = params.selectedTag;

  React.useEffect(() => {
    allTagsFetcher().then(setData, setError);
  }, []);
  var renderedEntry = false;
  if (error) {
    renderedEntry = (
      <Alert variant="outlined" severity="error">
        Cannot fetch tag list
      </Alert>
    );
  } else if (!data) {
    renderedEntry = <LinearProgress color="secondary" />;
  } else {
    renderedEntry = (
      <BlogTagList
        onSelection={(sel) => {
          if (sel) {
            navigate("/blog/tag/" + sel);
          } else {
            navigate("/blog/");
          }
        }}
        selected={currentTag}
        tags={data}
        size="large"
      />
    );
  }
  return renderedEntry;
};

const BlogEntryList = ({ fetcher }) => {
  const [data, setData] = React.useState(false);
  const [error, setError] = React.useState(false);

  const mdOrAbove = useMediaQuery((theme) => theme.breakpoints.up("md"));

  React.useEffect(() => {
    fetcher().then(setData, setError);
  }, [fetcher]);

  var renderedEntry = null;

  if (error) {
    renderedEntry = (
      <Alert variant="outlined" severity="error">
        An error occurred, please try again later.
      </Alert>
    );
  } else if (!data) {
    renderedEntry = <LinearProgress color="secondary" />;
  } else {
    var entries = (
      <>
        {data.map((entry) => (
          <BlogEntry data={entry} />
        ))}
      </>
    );
    renderedEntry = (
      <>
        {mdOrAbove ? (
          <Masonry
            columns={2}
            spacing={2}
            children={entries}
            sx={{ marginLeft: 0 }}
          />
        ) : (
          <Stack sx={{ p: 2 }} spacing={2} children={entries} />
        )}
      </>
    );
  }

  return renderedEntry;
};

const ö = (
  <Link
    href="https://en.wikipedia.org/wiki/Sensational_spelling"
    variant="h3"
    underline="none"
    sx={{ color: amber[600] }}
  >
    ö
  </Link>
);

const BlogHeader = () => (
  <>
    <Typography variant="h3">Bl{ö}g</Typography>
    <Typography sx={{ mb: 1 }} variant="h6">
      Assorted thoughts in no particular order
    </Typography>
    <br />
    <BlogTagsSection />
  </>
);

const Blog = () => {
  let params = useParams();

  return (
    <XqyPage>
      <HeaderAndContents header={<BlogHeader />}>
        <BlogEntryList
          fetcher={
            params.selectedTag
              ? getTagFetcher(params.selectedTag)
              : allEntriesFetcher
          }
        />
      </HeaderAndContents>
    </XqyPage>
  );
};

export default Blog;
