import * as React from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { blueGrey } from "@mui/material/colors";
import XqyConfig from "./XqyConfig";
import { Card, CardMedia, CardContent, CardActionArea } from "@mui/material";

import BlogTagList from "./BlogTagList";

const BlogEntry = ({ data }) => {
  let navigate = useNavigate();
  return (
    <Card sx={{ border: 1, borderColor: blueGrey[50] }} elevation={0}>
      <CardActionArea onClick={(event) => navigate("/blog/" + data.slug)}>
        {data.img && (
          <CardMedia
            component="img"
            sx={{ objectFit: "cover", backgroundColor: blueGrey[900] }}
            height={128}
            image={data.img}
          />
        )}

        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {data.title}
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            {data.subtitle}
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            posted by <b>{data.author}</b> ·{" "}
            {data.created === data.updated
              ? XqyConfig.dateFormatter(data.created)
              : `${XqyConfig.dateFormatter(
                  data.created
                )} [edited ${XqyConfig.dateFormatter(data.updated)}]`}
          </Typography>
          <BlogTagList variant="outlined" tags={data.tags} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BlogEntry;
