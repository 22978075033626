import Container from "@mui/material/Container";
import { useTheme } from "@mui/material";
const XqyPage = (rest) => {
    const theme = useTheme();
    return (
      <Container
        maxWidth="md"
        sx={{
          backgroundColor: { xs: "white", md: theme.palette.background.default },
          p: { xs: 0, md: 2 },
          mt: { md: 2 },
          mb: 2,
        }}
        {...rest}
      />
    );
  };

  export default XqyPage;