const getData = (x) => fetch(`/blogdata/${x}`);

const ENTRIES_JSON = "entries.json";

const tagMapper = (entries) =>
  Array.from(new Set(entries.map((entry) => entry.tags).flat()));

const tagFilter = (tagToFilter) => (entries) =>
  Array.from(
    new Set(entries.filter((entry) => entry.tags.includes(tagToFilter)).flat())
  );

const allEntriesFetcher = () =>
  getData(ENTRIES_JSON)
    .then((response) => response.json())
    .then((entries) =>
      entries
        .map((entry) =>
          Object.assign({}, entry, { updated: entry.updated || entry.created })
        )
        .sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        )
    );

const allTagsFetcher = () => allEntriesFetcher().then(tagMapper);

const getTagFetcher = (tag) => () => allEntriesFetcher().then(tagFilter(tag));

const fetchBySlug = (entries, slug) => {
  var ret = null;
  entries.forEach((e) => {
    if (e.slug === slug) {
      ret = getData(e.src)
        .then((response) => response.text())
        .then((bodyText) => Object.assign(e, { body: bodyText }));
    }
  });
  return ret;
};

export { allEntriesFetcher, allTagsFetcher, getTagFetcher, fetchBySlug };
