import * as React from "react";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
const NotFound = () => {
  return (
    <Container maxWidth="md">
      <Typography>
        <p>Page not found.</p>
      </Typography>
    </Container>
  );
};

export default NotFound;
