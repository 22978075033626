import * as React from "react";

import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import XqyConfig from "./XqyConfig";
import XqyMarkdown from "./XqyMarkdown";
import useMediaQuery from "@mui/material/useMediaQuery";
import BlogTagList from "./BlogTagList";
import { allEntriesFetcher, fetchBySlug } from "./BlogQuery";
import XqyPage from "./XqyPage";

import { HeaderAndContents } from "./XqyLayouts";

const BlogViewEntry = ({ data }) => {
  const mdOrAbove = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const headerSection = (
    <>
      {data.tags && <BlogTagList tags={data.tags} variant="outlined" />}
      <br />
      {data.title && (
        <Typography
          sx={{ fontWeight: "bold" }}
          variant={mdOrAbove ? "h3" : "h4"}
          component="div"
        >
          {data.title}
        </Typography>
      )}
      <Typography gutterBottom variant="h6" component="div">
        {data.subtitle}
      </Typography>

      <Typography gutterBottom variant="body2" component="div">
        posted by <b>{data.author}</b> ·{" "}
        <Link sx={{ color: "inherit" }} href={`/blog/${data.slug}`}>
          {data.created === data.updated
            ? XqyConfig.dateFormatter(data.created)
            : `${XqyConfig.dateFormatter(
                data.created
              )} [edited ${XqyConfig.dateFormatter(data.updated)}]`}
        </Link>
      </Typography>
    </>
  );

  const contents = (
    <Typography
      sx={{ p: { xs: 2, md: 0 } }}
      gutterBottom
      variant="body1"
      component="div"
    >
      <XqyMarkdown children={data.body} />
    </Typography>
  );

  return (
    <HeaderAndContents header={headerSection}>{contents}</HeaderAndContents>
  );
};

const BlogView = (props) => {
  const [data, setData] = React.useState(false);
  const [error, setError] = React.useState(false);
  let params = useParams();

  React.useEffect(() => {
    allEntriesFetcher().then(
      (entries) => {
        fetchBySlug(entries, params.blogEntry).then(setData, (reason) =>
          setError("Error loading content")
        );
      },
      (reason) => {
        setError("An error occurred, please try again later");
      }
    );
  }, [params.blogEntry]);

  var renderedEntry = null;

  if (error) {
    renderedEntry = (
      <Alert variant="outlined" severity="error">
        {error}
      </Alert>
    );
  } else if (!data) {
    renderedEntry = <LinearProgress color="secondary" />;
  } else {
    renderedEntry = <BlogViewEntry data={data} />;
  }

  return <XqyPage>{renderedEntry}</XqyPage>;
};

export default BlogView;
