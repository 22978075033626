import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

const BlogTagList = ({ tags, onSelection, selected, variant, color, ...rest }) => {
  let navigate = useNavigate();
  if (onSelection === undefined) {
    onSelection = (sel) => {
      if (sel) {
        navigate("/blog/tag/" + sel);
      } else {
        navigate("/blog/");
      }
    };
  }

  return (
    <Stack direction="row" spacing={1}>
      {tags.map((tag) => (
        <Chip
          key={tag}
          color={color || "secondary"}
          onDelete={selected === tag ? () => onSelection(null) : undefined}
          onClick={() => onSelection(tag)}
          size="small"
          label={tag}
          variant={
            selected === tag ? "filled" : selected ? "outlined" : variant
          }
          {...rest}
        />
      ))}
    </Stack>
  );
};

export default BlogTagList;
