import * as React from "react";
import { blueGrey } from "@mui/material/colors";
import { Card, Box, CardContent } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeaderAndContents = ({ header, children }) => {
  const mdOrAbove = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const headerSection = header ? (
    <Box
      component="div"
      sx={{ p: 2, color: "white", backgroundColor: blueGrey[800] }}
    >
      {header}
    </Box>
  ) : null;

  return mdOrAbove ? (
    <Card>
      {headerSection}
      <CardContent sx={{ p: { xs: 2, md: 3 } }}>{children}</CardContent>
    </Card>
  ) : (
    <>
      {headerSection}
      {children}
    </>
  );
};

export { HeaderAndContents };
