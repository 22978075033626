import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Paper from "@mui/material/Paper";
import { amber, blueGrey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";

import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { Link } from "react-router-dom";
import {default as MuiLink} from "@mui/material/Link";

const flatten = (text, child) => {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
};

const HeadingRenderer = props => {
  const children = React.Children.toArray(props.children);
  const text = children.reduce(flatten, '');
  const slug = text.toLowerCase().replace(/\W/g, '-');
  return React.createElement('h' + props.level, { id: slug }, props.children);
};

const CustomImg = (props) => {
  return <img {...props} style={{  maxWidth: "100%" }} />
}

const XqyMarkdown = (props) => (
  <ReactMarkdown
    components={{
      h1: HeadingRenderer,
      h2: HeadingRenderer,
      h3: HeadingRenderer,
      h4: HeadingRenderer,
      h5: HeadingRenderer,
      h6: HeadingRenderer,

      img: CustomImg,

      code: ({ inline, node, ...props }) => (
        <Paper
          elevation={0}
          sx={{
            fontFamily: "Monospace",
            p: 0.6,
            backgroundColor: blueGrey[50],
          }}
          component={inline ? "span" : undefined}
          {...props}
        />
      ),

      table: (props) => (
        <TableContainer component={Paper}>
          <Table size="small" {...props} />
        </TableContainer>
      ),
      thead: TableHead,
      tr: TableRow,
      tfoot: TableFooter,
      tbody: TableBody,
      td: TableCell,
      a: MuiLink,
      th: (props) => <TableCell sx={{ fontWeight: "bold" }} {...props} />,

      blockquote: (props) => (
        <Stack direction="row">
          <Box sx={{ m: 1, p: 0.1, backgroundColor: blueGrey[50] }} />
          <Typography sx={{ fontStyle: "italic" }} {...props} />
        </Stack>
      ),
    }}
    remarkPlugins={[remarkGfm]}
    {...props}
  />
);

export default XqyMarkdown;
