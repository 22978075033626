import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import PopupMenu from "./PopupMenu";
import { useResolvedPath, useMatch, useNavigate } from "react-router-dom";

const LinkButton = ({ children, to, onClick, ...props }) => {
  let resolved = useResolvedPath(to);
  let navigate = useNavigate();
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Button
      onClick={to ? () => navigate(to) : onClick}
      color={match ? "secondary" : "inherit"}
      {...props}
    >
      {children}
    </Button>
  );
};

const ResponsiveAppBar = ({ maxWidth = "lg", pages, rhs, logo, up = "md" }) => {
  const mdOrAbove = useMediaQuery((theme) => theme.breakpoints.up(up));

  return (
    <AppBar position="static" elevation={0}>
      <Container maxWidth={maxWidth}>
        <Toolbar disableGutters>
          {mdOrAbove ? (
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <Box sx={{ mr: 2, display: "flex" }}>{logo}</Box>
              {pages.map((page) => (
                <LinkButton
                  key={page.label}
                  to={page.to}
                  sx={{   display: "block" }}
                >
                  <Typography variant="body1">
                  {page.label}
                  </Typography>
                </LinkButton>
              ))}
            </Box>
          ) : (
            <>
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                <PopupMenu
                  choices={pages}
                  tooltip="Navigation"
                  icon={<MenuIcon />}
                  horizontal="left"
                />
              </Box>
              <Box sx={{ flexGrow: 1, display: "flex" }}>{logo}</Box>
            </>
          )}

          {rhs}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
