import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useResolvedPath, useMatch, useNavigate } from "react-router-dom";

const NavPageMenuItem = ({ handleCloseMenu, page }) => {
  let resolved = useResolvedPath(page.to);
  let navigate = useNavigate();
  let match = useMatch({ path: resolved.pathname, end: true });
 
  return (
    <MenuItem
      selected={match ? true : false}
      key={page.label}
      onClick={(event) => {
        navigate(page.to);
        handleCloseMenu(event);
      }}
    >
      <Typography textAlign="center">{page.label}</Typography>
    </MenuItem>
  );
};

const PageMenuItem = ({ handleCloseMenu, page }) => {
  if (page.to) {
    return <NavPageMenuItem key={page.label} handleCloseMenu={handleCloseMenu} page={page} />;
  }
  return (
    <MenuItem
      selected={page.selected}
      key={page.label}
      onClick={(event) => {
        page.onClick(event);
        handleCloseMenu(event);
      }}
    >
      <Typography textAlign="center">{page.label}</Typography>
    </MenuItem>
  );
};

const PopupMenu = ({ choices, horizontal, icon, tooltip,disabled}) => {
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);

  const handleOpenMenu = (event) => setAnchorElMenu(event.currentTarget);
  const handleCloseMenu = (event) => setAnchorElMenu(null);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title={tooltip}>
        <IconButton disabled={disabled} color="inherit" onClick={handleOpenMenu} sx={{ p: 0 }}>
          {icon}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: horizontal }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: horizontal }}
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        {choices.map((choice) => (
          <PageMenuItem handleCloseMenu={handleCloseMenu} page={choice} />
        ))}
      </Menu>
    </Box>
  );
};

export default PopupMenu;
