import * as React from "react";
import ResponsiveAppBar from "./ResponsiveAppBar";
// import PopupMenu from "./PopupMenu";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import { grey, blueGrey, amber } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import { IconButton } from "@mui/material";

import CssBaseline from "@mui/material/CssBaseline";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Roboto Serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    background: {
      default: grey[200],
    },
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: amber[600],
    },
  },
});

function App() {
  const pages = [
    {
      label: "Apps",
      to: "/apps",
    },
    {
      label: "Blog",
      to: "/blog",
    },
  ];

  const settings = [
    {
      label: "Profile",
    },
    {
      label: "Logout",
    },
  ];

  const logo = (
    <Stack direction="row" alignItems="center" gap={1}>
      <Link underline="none" href="/" color="inherit">
        <Typography variant="h6">:: xqy.ro</Typography>
      </Link>
    </Stack>
  );

  // const userMenu = (
  //   <PopupMenu
  //     choices={settings}
  //     tooltip="Open settings"
  //     icon={<AccountCircleIcon sx={{ m: 1 }} />}
  //     horizontal="right"
  //   />
  // );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ResponsiveAppBar
        up="sm"
        maxWidth="md"
        pages={pages}
        settings={settings}
        rhs={
          <Box sx={{ display: "flex" }}>
            <IconButton color="inherit" href="mailto:contact@xqy.ro">
              <EmailIcon />
            </IconButton>
            <IconButton color="inherit" href="https://fb.com/xqy.ro">
              <FacebookIcon />
            </IconButton>
            {/* {userMenu} */}
          </Box>
        }
        logo={logo}
      />
      <Outlet />

      <Typography sx={{ p: 2, textAlign: "center" }} variant="body2">
        Copyright © 2022-present xqy.ro. All rights reserved
      </Typography>
    </ThemeProvider>
  );
}

export default App;
