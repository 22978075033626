import * as React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Masonry from "@mui/lab/Masonry";
import { Typography } from "@mui/material";

import XqyApp, { playLink } from "./XqyApp";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "@mui/material/Link";

const xqyAppData = [
  {
    title: "Metapx",
    subtitle: "📱 Android • ro.xqy.metapx",
    storeLink: playLink("ro.xqy.metapx"),
    img: "/img/apps/metapx.png",

    imgSx: { objectFit: "scale-down", backgroundColor: "#6200EE" },
    body: (
      <>
        <p>
          Metapx uses the EXIF data of your photos to place a customizable
          watermark comprising camera and lens models, exposure time and
          aperture, as well as the date and time of the photo and where it was
          taken. You can add your own notes, or the address of your profile or
          website.
        </p>

        <p>
          You can customize the layout, colors, and fonts of the rendered
          watermark, and even allow yourself to get a little creative if you
          like.
        </p>
      </>
    ),
  },

  {
    title: "Pyramid Twist Puzzle",
    subtitle: "📱 Android • ro.xqy.pyramid",
    storeLink: playLink("ro.xqy.pyramid"),
    img: "/img/apps/pyramid.png",
    imgSx: { objectFit: "scale-down" },
    body: (
      <>
        <p>Solve the Pyraminx puzzle on your Android device.</p>

        <p>
          The game also includes Master Pyraminx, Professor Pyraminx and Royal
          Pyraminx.
        </p>
        <p>
          Please visit{" "}
          <Link href="http://www.mefferts.com">Meffert's Puzzles</Link> for the
          original mechanical puzzle.
        </p>
      </>
    ),
  },

  {
    title: "RandomPick",
    subtitle: "⌚ Garmin • Connect IQ",
    storeLink:
      "https://apps.garmin.com/en-US/apps/92fdff25-8358-4c4a-ab5e-60788f3f0520",
    img: "/img/apps/randompick.png",

    imgSx: { objectFit: "scale-down", backgroundColor: "black" },
    body: (
      <>
        <p>
          A configurable widget for your Garmin watch that you can use to flip a
          coin, roll dice, or just generate random numbers.
        </p>
        <p>
          RandomPick exists mainly to solve the classic problem of deciding on
          restaurant meals. For the same reason deciding on meals is a problem,
          the app is extremely configurable.
        </p>
      </>
    ),
  },

  {
    title: "Strips",
    subtitle: "📱 Android • ro.xqy.strips",
    storeLink: playLink("ro.xqy.strips"),
    img: "/img/apps/strips.jpg",
    body: (
      <>
        <p>
          Strips is an Android live wallpaper showing pulsating strips of color.
        </p>

        <p>
          The wallpaper comes with several themes and can be further customized.
        </p>
      </>
    ),
  },
];

const Apps = () => {
  const mdOrAbove = useMediaQuery((theme) => theme.breakpoints.up("md"));

  var apps = (<>{xqyAppData.map((app) => (
    <XqyApp {...app} />
  ))}</>);
  return (
    <Container maxWidth="md" sx={{ mt: 2, mb: 2 }}>
      <Typography variant="h3">Apps</Typography>
      <br />
      {mdOrAbove ?
      (<Masonry columns={2} spacing={3} children={apps} sx={{margin:0}}/>):
      (<Stack spacing={2} children={apps}/>)}
    </Container>
  );
};

export default Apps;
