import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActions } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

const XqyApp = ({ img, imgSx, title, body, subtitle, storeLink }) => {
  return (
    <Card sx={{}}>
      <CardMedia
        component="img"
        height={128}
        sx={[{ backgroundColor: blueGrey[900] }, imgSx]}
        image={img}
        alt="App logo"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography gutterBottom variant="body2" component="div">
          {subtitle}
        </Typography>
        <Typography component="div" variant="body1" color="text.secondary">
          {body}
        </Typography>
      </CardContent>{" "}
      <CardActions>
        {storeLink && (
          <Button href={storeLink} color="secondary">
            View details
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const playLink = (pkg) =>
  `https://play.google.com/store/apps/details?id=${pkg}`;

export { playLink };
export default XqyApp;
